import React from 'react';
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Link,
  Theme,
} from '@material-ui/core';
import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active': {
        textDecoration: 'none',
      },
    },
    card: {
      width: '340px',
      height: '190px',
      border: `1px solid ${colors.sparkBlue}`,
      borderRadius: '5px',
      boxShadow: `0px 1px 3px ${colors.boxShadowColor}`,
      marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(4),
      alignContent: 'baseline',
      '&:hover': {
        background: colors.backgroundLightBlue,
        boxShadow: `4px 3px 3px ${colors.boxShadowColor}`,
      },
      [theme.breakpoints.down('sm')]: {
        width: '330px',
        height: '144px',
        marginBottom: '20px',
        marginLeft: '0',
        padding: 'unset',
      },
    },
    cardTitle: {
      height: 'fit-content',
      marginTop: '20px',
      color: colors.brightBlue,
      fontSize: '26px',
      fontWeight: 'bold',
    },
    cardDescription: {
      height: 'fit-content',
      color: colors.noirBlurLight,
      textAlign: 'center',
      marginTop: '10px',
    },
  }),
);
type Props = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  title: string;
  desc: string;
  testid?: string;
};
const UserTypeCard = ({ title, desc, testid, onClick }: Props) => {
  const classes = useStyles();

  return (
    <Link onClick={onClick} className={classes.link} data-testid={testid}>
      <Grid container item className={classes.card}>
        <Typography className={classes.cardTitle} data-testid="card-title">
          {title}
        </Typography>
        <Typography className={classes.cardDescription} data-testid="card-desc">
          {desc}
        </Typography>
      </Grid>
    </Link>
  );
};

export default UserTypeCard;
