import { useStaticQuery, graphql } from 'gatsby';
import { Query } from '../../../__generated__/gatsby-types';

export const residentialCustomersFAQQuery = graphql`
  {
    contentfulModuleAccordion(contentful_id: { in: "57ij21ZD9eJSL97Lw9YQUV" }) {
      ...ModuleAccordion
    }
  }
`;

export default () => {
  const { contentfulModuleAccordion } = useStaticQuery<Query>(
    residentialCustomersFAQQuery,
  );

  return {
    contentfulModuleAccordion,
  };
};
