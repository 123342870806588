import React, { FC, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import colors from '../../../themes/main-colors';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEAccordion from '../../accordion/PGEAccordion';
import ROUTES from '../../../routes';
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Theme,
  Container,
} from '@material-ui/core';
import UserTypeCard from './UserTypeCard';
import ResidentialCustomersFAQData from './contentfulQueries';
import useAuth from '../../../hooks/useAuth';
import SignInModal from '../../sign-in-form/SignInModal';
import { AccountType } from '../../../__generated__/pge-types';
import Backdrop from '../../backdrop';
import useGetAccountType from '../../../hooks/useGetAccountType';
import useAccountCustomer from '../../../hooks/useAccountCustomer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(4, 0),
    },
    header: {
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 100,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
      },
    },
    cardSection: {
      margin: theme.spacing(6, 0),
      padding: theme.spacing(0, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0),
        margin: theme.spacing(6, 0, 0),
      },
    },
    cards: {
      marginTop: '50px',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        flexDirection: 'column',
      },
    },
    infoBanner: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: theme.spacing(5),
      backgroundColor: colors.lightBlue1,
      boxShadow: theme.shadows[3],
      borderRadius: theme.spacing(0.625),
    },
    infoBannerTitle: {
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      fontWeight: theme.spacing(12.5),
      lineHeight: theme.typography.pxToRem(36),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
    },
    infoBannerDesc: {
      marginTop: theme.spacing(4),
      fontSize: theme.spacing(3),
    },
    faq: {
      marginBottom: '200px',
      '& .MuiAccordionDetails-root': {
        fontSize: theme.spacing(2.4),
      },
      '& .MuiTypography-h4': {
        marginBottom: theme.spacing(3),
      },
    },
  }),
);

const ResidentialCustomers: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(
    false,
  );
  const { isAuthenticated } = useAuth();
  const { contentfulModuleAccordion } = ResidentialCustomersFAQData();

  const [isLoading, setIsLoading] = useState<boolean>(
    isAuthenticated ? true : false,
  );
  
  const { customer } = useAccountCustomer();
  const hasNoAccounts = customer && customer.accountMeta?.totalAccounts === 0;
  if (hasNoAccounts) {
    void navigate(ROUTES.SSM_START_ADDRESS);
  }

  const { accountTypeData } = useGetAccountType({});

  useEffect(() => {
    if (
      accountTypeData &&
      accountTypeData.getAccountDetails[0].accountType === AccountType.Res
    ) {
      redirectResidentialCustomer();
    } else if (accountTypeData) {
      setIsLoading(false);
    }
  }, [accountTypeData]);

  const redirectResidentialCustomer = () => {
    void navigate(ROUTES.SSM_HOME);
  };

  const handleNewUserClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    void navigate(ROUTES.SSM_START);
  };

  const handleExistingOrReturningUserClick = (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault();

    if (isAuthenticated) {
      void navigate(ROUTES.SSM_HOME);
    } else {
      setIsLoginModalVisible(true);
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Typography
          className={classes.header}
          variant="h1"
          data-testid="header"
        >
          {t('RESIDENTIAL')} {t('CUSTOMERS')}
        </Typography>

        {isLoading && <Backdrop forceOpen />}

        <Grid container className={`${classes.infoBanner}`}>
          <Container>
            <Typography className={classes.header} data-testid="banner-title">
              {t('SSM_RESIDENTIAL_BANNER_TITLE')}
            </Typography>
            <Typography
              className={classes.infoBannerDesc}
              data-testid="banner-desc"
              variant="h6"
            >
              {t('SSM_RESIDENTIAL_BANNER_DESC')}
            </Typography>
          </Container>
        </Grid>

        <Grid container className={classes.cardSection}>
          <Container>
            <Typography
              className={classes.infoBannerTitle}
              data-testid="choose-customer-type-title"
            >
              {t('SSM_CHOOSE_CUSTOMER_TYPE_TITLE')}
            </Typography>
          </Container>

          <Grid container item className={classes.cards}>
            <UserTypeCard
              title={t('EXISTING_CUSTOMER')}
              desc={t('EXISTING_CUSTOMER_DESC')}
              onClick={e => handleExistingOrReturningUserClick(e)}
              testid="existing-customer"
            />
            <UserTypeCard
              title={t('NEW_CUSTOMER')}
              desc={t('NEW_CUSTOMER_DESC')}
              onClick={e => handleNewUserClick(e)}
              testid="new-customer"
            />
            <UserTypeCard
              title={t('RETURNING_CUSTOMER')}
              desc={t('RETURNING_CUSTOMER_DESC')}
              onClick={e => handleExistingOrReturningUserClick(e)}
              testid="returning-customer"
            />
          </Grid>
        </Grid>

        <Grid container className={classes.faq}>
          <PGEAccordion
            colorBackground={colors.gray93 + '61'}
            accordionItems={contentfulModuleAccordion?.accordionItems}
            accordionHeader={contentfulModuleAccordion?.accordionHeader}
          />
        </Grid>
      </Grid>

      {!isAuthenticated && (
        <SignInModal
          isOpen={isLoginModalVisible}
          redirectUrl={ROUTES.SSM_HOME}
          handleClose={() => setIsLoginModalVisible(false)}
        />
      )}
    </>
  );
};

export default ResidentialCustomers;
