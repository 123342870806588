import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from '../../../hooks/useTranslation';
import { Container } from '@material-ui/core';
import ResidentailCustomers from '../../../components/ssm/landing-page/ResidentialCustomers';
import CaliforniaUsers from '../../../components/out-of-state/CaliforniaUsers';

const Residential: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('RESIDENTIAL_CUSTOMERS_PAGE_TITLE')}</title>
      </Helmet>
      <Container>
        <CaliforniaUsers />
        <ResidentailCustomers />
      </Container>
    </>
  );
};

export default Residential;
